<template>
  <section>
    <DynamicInvoiceModal
      :htmlString="invoiceHtmlString"
      :id="invoiceId"
      pdfName="rent"
      @send-email="sendInvoiceEmail"
    />
    <AcceptModal
      v-if="showAcceptModal"
      :rent-id="currentRentId"
      :should_start_rent_after_accepting="should_start_rent_after_accepting"
      :currentVehicleCategory="currentVehicleCategory"
      :currentVehicleModel="currentVehicleModel"
      v-on:close="onAcceptModalClose"
    />
    <RejectModal
      v-if="showRejectModal"
      :rent-id="currentRentId"
      v-on:close="onRejectModalClose"
    />
    <CancelModal
      v-if="showCancelModal"
      :rent-id="currentRentId"
      v-on:close="onCancelModalClose"
    />
    <DeliverModal
      v-if="showDeliverModal"
      :rent-id="currentRentId"
      v-on:close="onDeliverModalClose"
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus :title="pageTitle" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="
              $t('components.vehicleRentalsManagement.summary.totalRents')
            "
            :value="`${indexMetaData.summary.total}`"
            variant="gray"
            size="small"
          />

          <SummaryCard
            :title="$t('components.vehicleRentalsManagement.summary.pending')"
            :value="` ${indexMetaData.summary.requested}`"
            variant="gray"
            size="small"
          />

          <SummaryCard
            :title="$t('components.vehicleRentalsManagement.summary.accepted')"
            :value="
              ` ${indexMetaData.summary.accepted +
                indexMetaData.summary.delivered}`
            "
            variant="gray"
            size="small"
          />

          <SummaryCard
            :title="
              $t(
                'components.vehicleRentalsManagement.summary.rejectedCancelled'
              )
            "
            :value="
              ` ${indexMetaData.summary.rejected +
                indexMetaData.summary.cancelled}`
            "
            variant="gray"
            size="small"
          />

          <SummaryCard
            :title="$t('components.vehicleRentalsManagement.summary.completed')"
            :value="` ${indexMetaData.summary.completed}`"
            variant="gray"
            size="small"
          />
        </div>
      </div>

      <div
        v-if="$acl.canNotView('rentals')"
        class="my-5 font-bold text-center text-gray-600"
      >
        You do not have enough permission to view it.
      </div>
      <div v-else>
        <FSTable
          :qso="qso"
          :fst-id="fstId"
          :headers="getTableHeadersName"
          :endpoint="endpoint"
          :currency-enabled="false"
          :currency-attributes="getCurrencyAttributes"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="filterOptions"
              />
            </keep-alive>
          </template>

          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow
                  :key="itemIndex"
                  :className="{
                    'fst-row': true,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'VehicleRentalDetail',
                        params: { id: item.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.id.substr(item.id.length - 5) }}
                    </router-link>
                  </FSTableRowItem>

                  <FSTableRowItem
                    v-if="haveRiderColumn"
                    :text="item.rider.full_name"
                    :to="{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.rider.id },
                    }"
                  />

                  <FSTableRowItem v-if="item.vehicle">
                    <router-link
                      :to="{
                        name: 'ViewVehicleProfile',
                        params: { id: item.vehicle.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.vehicle.qr_code }}
                    </router-link>
                    <p class="text-gray-400 text-sm">
                      {{
                        item.organization_vehicle_model
                          ? item.organization_vehicle_model.title
                          : '--'
                      }}
                    </p>
                  </FSTableRowItem>

                  <FSTableRowItem v-if="!item.vehicle">
                    <p>{{ `--` }}</p>
                    <p class="text-gray-400 text-sm">
                      {{
                        item.organization_vehicle_model
                          ? item.organization_vehicle_model.title
                          : '--'
                      }}
                    </p>
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        item.start_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem
                    :text="
                      getFormattedDateTime(
                        item.end_time,
                        'D MMM, YY hh:mm:ss a'
                      )
                    "
                  />

                  <FSTableRowItem
                    :text="
                      milisecToDHMSConverter(item.duration_in_seconds * 1000)
                    "
                  />

                  <FSTableRowItem :text="getCostLine(item)" />

                  <FSTableRowItem
                    :text="item.invoice ? item.invoice.payment_method : '--'"
                  />
                  <FSTableRowItem>
                    <template v-if="getFlags(item).length > 0">
                      <div class="flex flex-wrap items-center">
                        <template v-for="(flag, flagIndex) in getFlags(item)">
                          <trip-flag-icon
                            v-tooltip.bottom="getFlagAlias(flag, item)"
                            size="sm"
                            class="mr-1"
                            :name="flag"
                            :key="`rental-${itemIndex}-flag-${flagIndex}`"
                          />
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      {{
                        $t(
                          'components.vehicleRentalsManagement.table.values.noFlags'
                        )
                      }}
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <XStatus
                      :text="getRentStatus(item)"
                      :variant="getRentStatusBadge(item)"
                      :profile="`trip`"
                      v-if="!shouldShowStatusTooltip(item.status)"
                    />
                    <XStatus
                      :text="getRentStatus(item)"
                      :variant="getRentStatusBadge(item)"
                      :profile="`trip`"
                      v-if="shouldShowStatusTooltip(item.status)"
                      v-tooltip.bottom="getTooltipText(item)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-eye-icon
                        v-tooltip.bottom="'View Invoice'"
                        @click="onInvoiceModalReq(item.id)"
                      />

                      <t-dropdown
                        v-if="
                          item.status === 'REQUESTED' ||
                            item.status === 'ACCEPTED' ||
                            item.status === 'DELIVERED' ||
                            item.status === 'TRIP_STARTED'
                        "
                        variant="smActions"
                      >
                        <template
                          #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                        >
                          <button
                            class="focus:outline-none"
                            aria-label="More Actions"
                            aria-haspopup="true"
                            @mousedown="mousedownHandler"
                            @focus="focusHandler"
                            @blur="blurHandler"
                            @keydown="keydownHandler"
                          >
                            <svg
                              class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                              ></path>
                            </svg>
                          </button>
                        </template>

                        <template #default="{ hide }">
                          <div
                            @click="hide()"
                            class="py-1 mt-px bg-white rounded-md shadow-md"
                          >
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  should_start_rent_after_accepting:
                                    item.should_start_rent_after_accepting,
                                  vehicle_category: item.vehicle_category,
                                  vehicle_model: item.organization_vehicle_model
                                    ? item.organization_vehicle_model.title
                                    : '',
                                  action: 'accept',
                                })
                              "
                              v-if="item.status === 'REQUESTED'"
                            >
                              Accept
                            </t-dropdown-item>
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  action: 'reject',
                                })
                              "
                              v-if="item.status === 'REQUESTED'"
                            >
                              Reject
                            </t-dropdown-item>
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  action: 'deliver',
                                })
                              "
                              v-if="item.status === 'ACCEPTED'"
                            >
                              Mark as Delivered
                            </t-dropdown-item>
                            <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  user: item.rider,
                                  action: 'force-complete-open',
                                })
                              "
                              v-if="
                                item.status !== 'REQUESTED' &&
                                  item.status !== 'ACCEPTED' &&
                                  item.status !== 'TRIP_COMPLETED' &&
                                  item.status !== 'CANCELLED' &&
                                  item.status !== 'REJECTED'
                              "
                            >
                              {{
                                $t(
                                  'components.vehicleRentalsManagement.actions.forceComplete'
                                )
                              }}
                            </t-dropdown-item>
                            <!-- <t-dropdown-item
                              @click="
                                onPerformAction({
                                  id: item.id,
                                  action: 'cancel',
                                })
                              "
                              v-if="
                                item.status !== 'REQUESTED' &&
                                  item.status !== 'ACCEPTED' &&
                                  item.status !== 'TRIP_COMPLETED' &&
                                  item.status !== 'CANCELLED' &&
                                  item.status !== 'REJECTED'
                              "
                            >
                               {{
                                  $t(
                                    'components.vehicleRentalsManagement.actions.forceCancel'
                                  )
                                }}
                            </t-dropdown-item> -->
                          </div>
                        </template>
                      </t-dropdown>
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  :className="{
                    'fst-row': true,
                  }"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'VehicleRentalDetail',
                        params: { id: item.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.id.substr(item.id.length - 5) }}
                    </router-link>
                  </FSTableRowItem>
                  <FSTableRowItem v-if="item.vehicle">
                    <router-link
                      :to="{
                        name: 'ViewVehicleProfile',
                        params: { id: item.vehicle.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      {{ item.vehicle.qr_code }}
                    </router-link>
                    <p class="text-gray-400 text-sm">
                      {{
                        item.organization_vehicle_model
                          ? item.organization_vehicle_model.title
                          : '--'
                      }}
                    </p>
                  </FSTableRowItem>

                  <FSTableRowItem v-if="!item.vehicle">
                    <p>{{ `--` }}</p>
                    <p class="text-gray-400 text-sm">
                      {{
                        item.organization_vehicle_model
                          ? item.organization_vehicle_model.title
                          : '--'
                      }}
                    </p>
                  </FSTableRowItem>
                  <!-- <FSTableRowItem>
                    <router-link
                      :to="{
                        name: 'ViewVehicleProfile',
                        params: { id: item.vehicle.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                      v-if="item.vehicle"
                    >
                      {{ item.vehicle.qr_code }}
                    </router-link>
                    <span v-else>--</span>
                  </FSTableRowItem> -->

                  <FSTableRowItem>
                    <XStatus
                      :text="getRentStatus(item)"
                      :variant="getRentStatusBadge(item)"
                      :profile="`trip`"
                      v-if="!shouldShowStatusTooltip(item.status)"
                    />
                    <XStatus
                      :text="getRentStatus(item)"
                      :variant="getRentStatusBadge(item)"
                      :profile="`trip`"
                      v-if="shouldShowStatusTooltip(item.status)"
                      v-tooltip.bottom="getTooltipText(item)"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Start Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.start_time,
                            'D MMM, YY hh:mm:ss a'
                          )
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">End Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.end_time,
                            'D MMM, YY hh:mm:ss a'
                          )
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Duration</div>
                      <div class="col-span-5 right-text">
                        {{ getDuration(item.duration, 'hours') }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Charge</div>
                      <div class="col-span-5 right-text">
                        {{ getCostLine(item) }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Payment Method</div>
                      <div class="col-span-5 right-text">
                        {{ item.invoice.payment_method || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Flags</div>
                      <div class="col-span-5 right-text">
                        <template v-if="getFlags(item).length > 0">
                          <div class="flex flex-wrap items-center">
                            <template
                              v-for="(flag, flagIndex) in getFlags(item)"
                            >
                              <trip-flag-icon
                                v-tooltip.bottom="getFlagAlias(flag, item)"
                                size="sm"
                                class="mr-1"
                                :name="flag"
                                :key="`rent-${itemIndex}-flag-${flagIndex}`"
                              />
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          No Flags
                        </template>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Actions</div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <oto-eye-icon
                            v-tooltip.bottom="'View Invoice'"
                            @click="onInvoiceModalReq(item.id)"
                          />

                          <t-dropdown
                            v-if="
                              item.status === 'REQUESTED' ||
                                item.status === 'ACCEPTED' ||
                                item.status === 'DELIVERED' ||
                                item.status === 'TRIP_STARTED'
                            "
                            variant="smActions"
                          >
                            <template
                              #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                            >
                              <button
                                class="focus:outline-none"
                                aria-label="More Actions"
                                aria-haspopup="true"
                                @mousedown="mousedownHandler"
                                @focus="focusHandler"
                                @blur="blurHandler"
                                @keydown="keydownHandler"
                              >
                                <svg
                                  class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                  ></path>
                                </svg>
                              </button>
                            </template>

                            <template #default="{ hide }">
                              <div
                                @click="hide()"
                                class="py-1 mt-px bg-white rounded-md shadow-md"
                              >
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      vehicle_category: item.vehicle_category,
                                      action: 'accept',
                                    })
                                  "
                                  v-if="item.status === 'REQUESTED'"
                                >
                                  Accept
                                </t-dropdown-item>
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      action: 'reject',
                                    })
                                  "
                                  v-if="item.status === 'REQUESTED'"
                                >
                                  Reject
                                </t-dropdown-item>
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      action: 'deliver',
                                    })
                                  "
                                  v-if="item.status === 'ACCEPTED'"
                                >
                                  Mark as Delivered
                                </t-dropdown-item>
                                <t-dropdown-item
                                  @click="
                                    onPerformAction({
                                      id: item.id,
                                      action: 'cancel',
                                    })
                                  "
                                  v-if="
                                    item.status !== 'REQUESTED' &&
                                      item.status !== 'ACCEPTED' &&
                                      item.status !== 'TRIP_COMPLETED' &&
                                      item.status !== 'CANCELLED' &&
                                      item.status !== 'REJECTED'
                                  "
                                >
                                  Cancel
                                </t-dropdown-item>
                              </div>
                            </template>
                          </t-dropdown>
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </div>
    </content-section>

    <EdgeStack
      :id="tripAction.esId"
      :busy="tripAction.busy"
      :exit-button="{ text: 'Cancel', hidden: true }"
      :footer-class="{
        fixed: 'flex justify-end items-center px-6 pt-2 pb-5 w-full h-auto',
        append: '',
      }"
    >
      <template #header>
        <div class="text-xl font-bold">{{ tripAction.title }}</div>
      </template>

      <template #footer="{ close }">
        <div class="flex items-center gap-5">
          <AppButton text="Cancel" variant="secondary" @click="close()" />

          <AppButton
            text="Confirm"
            :disabled="getClickableStatus"
            :isLoading="tripAction.processing"
            @click="
              onPerformAction({
                id: tripAction.primaryKey,
                action: 'force-complete-execute',
              })
            "
          />
        </div>
      </template>

      <template #default>
        <div class="flex items-center px-3">
          <div class="w-14">
            <div
              class="flex items-center justify-center bg-gray-200 rounded-full w-9 h-9"
            >
              <img
                :src="tripAction.data.user.profile_pic"
                class="object-contain rounded-full w-9 h-9"
              />
            </div>
          </div>

          <div class="flex items-center justify-between w-full py-4 border-b">
            <div>
              <h5
                v-text="tripAction.data.user.full_name"
                class="text-base text-black"
              />
              <h6
                v-text="'UserID: ' + $truncate(tripAction.data.user.id, -5)"
                class="text-sm tracking-wide"
                style="color: #797979"
              />
            </div>

            <div
              class="text-right text-blue-600"
              v-text="`RentID: ${$truncate(tripAction.primaryKey, -5)}`"
            />
          </div>
        </div>

        <div class="flex justify-end  my-2 px-3" v-if="isRentalTimeExtended">
          <div
            class="max-w-md py-4 bg-gray-200 border rounded-md px-3"
            style="min-width: 350px; background: #F3F3F3;"
          >
            <section class="flex items-center justify-between gap-4 ">
              <div class="-mt-6">Extended Duration (mins)</div>

              <div class="w-24">
                <AppInput
                  v-model="tripAction.models.extended_duration_in_mins"
                  type="number"
                  step="1"
                />
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex items-center justify-between py-1">
                <div class="w-full">
                  Fee ({{ tripAction.models.extended_duration_in_mins }} mins x
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.rent_time_extension_fees_per_minute
                  }}
                  )
                </div>

                <div>
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.trip_amount
                  }}
                </div>
              </div>
            </section>

            <section class="py-2 border-t border-gray-300">
              <div class="flex items-center justify-between  py-1">
                <div class="w-full">
                  Subtotal
                </div>

                <div>
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.sub_total_amount
                  }}
                </div>
              </div>
              <div class="flex items-center justify-betweenpy-1">
                <div class="w-full">
                  Vat ({{ getTripActionCharges.tax_percentage }}%)
                </div>

                <div>
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.vat_amount
                  }}
                </div>
              </div>
            </section>

            <section class="pt-3 border-t border-gray-300">
              <div class="flex items-center justify-between">
                <div class="w-full">
                  Total
                </div>

                <div class=" text-3xl ">
                  {{
                    getTripActionCharges.currency_symbol +
                      getTripActionCharges.total_amount
                  }}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Reason (Required)`" />
            <AppInput
              type="richselect"
              rules="required"
              name="reason"
              placeholder="Select a reason"
              value-attribute="value"
              text-attribute="text"
              :options="forceCompleteReason"
              hide-search-box
              v-model="tripAction.models.force_complete_reason"
            />
          </div>
        </div>

        <div class="flex px-3 py-3">
          <div class="w-14">
            <div class="flex items-center justify-center rounded-full w-9 h-9">
              <img src="@/assets/icon/note.svg" />
            </div>
          </div>
          <div class="w-full">
            <h5 class="text-base text-black" v-text="`Remarks (Required)`" />
            <AppInput type="textarea" v-model="tripAction.models.remarks" />
          </div>
        </div>
      </template>
    </EdgeStack>
  </section>
</template>

<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
  generateCurrencyAttributes,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
// import { resolveProp } from '@/utils'
import {
  getHumanReadableDuration,
  getFormattedDateTime,
} from '@/utils/datetime'
import EdgeStack from '@/components/modals/EdgeStack'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { milisecToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils'
import { xMan } from '@/utils'

export default {
  name: 'VehicleRentalIndex',

  props: {
    pageTitle: {
      type: String,
      default: 'Vehicle Rentals',
    },
    fstId: {
      type: String,
      default: 'vehicle-rentals',
    },
    endpoint: {
      type: String,
      default: useEndpoints.vehicleRental.index(),
    },
    haveRiderColumn: {
      type: Boolean,
      default: true,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    XStatus: () => import('@/components/badge/XStatus'),
    // MoreActionsDropdown: () =>
    //   import('@/components/dropdown/MoreActionsDropdown'),
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    TripFlagIcon: () => import('@/components/badge/TripFlagIcon'),
    DynamicInvoiceModal: () =>
      import('@/components/modals/DynamicInvoiceModal.vue'),
    AcceptModal: () => import('@/composites/vehicle-rental/modals/AcceptModal'),
    RejectModal: () => import('@/composites/vehicle-rental/modals/RejectModal'),
    CancelModal: () => import('@/composites/vehicle-rental/modals/CancelModal'),
    DeliverModal: () =>
      import('@/composites/vehicle-rental/modals/DeliverModal'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,

    EdgeStack,
    TDropdownItem,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      currentRentId: null,
      currentVehicleCategory: null,
      currentVehicleModel: null,
      should_start_rent_after_accepting: null,
      showAcceptModal: false,
      showRejectModal: false,
      showCancelModal: false,
      showDeliverModal: false,
      indexMetaData: {
        summary: {
          accepted: 0,
          delivered: 0,
          rejected: 0,
          cancelled: 0,
          completed: 0,
          requested: 0,
          total: 0,
        },
        count: {
          total: 0,
        },
      },

      tableHeaders: [
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.rentId'
          ),
          width: '9%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.rider'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.vehicleQR'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.startTime'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.endTime'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.duration'
          ),
          width: '6%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.charge'
          ),
          width: '5%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.paymentMethod'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.flags'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.status'
          ),
          width: '6%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.actions'
          ),
          width: '5%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.rentId'
          ),
          width: '20%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.vehicleQR'
          ),
          width: '40%',
          sort: 'bike',
        },
        {
          text: this.$t(
            'components.vehicleRentalsManagement.table.columns.charge'
          ),
          width: '30%',
          sort: 'cost',
        },
      ],
      opened: [],
      invoiceModalDetails: {},

      // generated by generateFilterOptions()
      filterOptions: [],

      tripAction: {
        busy: false,
        esId: 'rentActionES',
        title: 'Force Complete Rent',

        primaryKey: '',
        processing: false, //

        models: {
          extended_duration_in_mins: 0, // in min
          force_complete_reason: '',
          remarks: '',
        },

        data: {
          user: {
            id: '--',
            full_name: '--',
            role_name: '--',
            profile_pic: 'https://via.placeholder.com/150',
          },

          pricing_info: {
            extended_duration_in_mins: 0,
            currency_symbol: '~',
            rent_time_extension_fees_per_minute: 0,
            tax_percentage: 0,
            estimated_extended_cost: 0,
            estimated_extension_tax_amount: 0,
            is_time_extended: false,
          },
        },
      },
      forceCompleteReason: [
        {
          text: 'Vehicle Locking Issue',
          value: 'VEHICLE_LOCKING_ISSUE',
        },
        {
          text: 'Vehicle Battery Died',
          value: 'VEHICLE_BATTERY_DIED',
        },
        {
          text: 'Vehicle Inactive',
          value: 'VEHICLE_INACTIVE',
        },
        {
          text: 'App Issues',
          value: 'APP_ISSUES',
        },
        {
          text: 'Other',
          value: 'OTHER',
        },
      ],
      invoiceHtmlString: '',
      invoiceId: '',
    }
  },

  computed: {
    getTableHeadersName() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.getTableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this.$org.default_currency.symbol
    },
    getTableHeaders() {
      if (this.haveRiderColumn) {
        return this.tableHeaders
      } else {
        return this.tableHeaders.filter((item) => item.text !== 'Rider')
      }
    },
    getCurrencyAttributes() {
      return generateCurrencyAttributes({
        root: 'invoiced_charges',
        // def: {
        //   amount: 'settled_amount',
        //   currencyName: 'settled_currency.name',
        //   currencySymbol: 'settled_currency.symbol',
        // },
        def: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
        actual: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
      })
    },
    isRentalTimeExtended() {
      if (this.tripAction.data.pricing_info.is_time_extended) {
        return true
      } else {
        return false
      }
    },
    getClickableStatus() {
      if (
        this.tripAction.models.force_complete_reason &&
        this.tripAction.models.remarks
      ) {
        return false
      } else {
        return true
      }
    },

    getTripActionCharges() {
      const p = this.tripAction.data.pricing_info
      const t = this.tripAction.models.extended_duration_in_mins

      const trip_amount_raw =
        Number(p.rent_time_extension_fees_per_minute) * Number(t)

      const trip_amount = Number(trip_amount_raw).toFixed(2)

      const sub_total_amount = Number(trip_amount).toFixed(2)

      let vat_amount_raw = p.tax_percentage
        ? (p.tax_percentage / 100) * trip_amount
        : 0

      let vat_amount = Number(vat_amount_raw).toFixed(2)

      let total_amount = (
        Number(sub_total_amount) + Number(vat_amount)
      ).toFixed(2)

      return { ...p, trip_amount, sub_total_amount, vat_amount, total_amount }
    },
  },

  async created() {
    await this.generateFilterOptions()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    getFormattedDateTime,
    onApplyFilterDateRange,
    milisecToDHMSConverter,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async generateFilterOptions() {
      this.filterOptions = [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Accepted', value: 'ACCEPTED' },
            { text: 'Pending', value: 'REQUESTED' },
            { text: 'Rejected', value: 'REJECTED' },
            { text: 'Cancelled', value: 'CANCELLED' },
            { text: 'Started', value: 'DELIVERED' },
            { text: 'Completed', value: 'TRIP_COMPLETED' },
          ],
          title: 'Status',
        },

        {
          key: 'rentCost',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.currencySymbol,
            min: -9999,
            max: 9999,
            minQs: 'min_cost', // query string for min value
            maxQs: 'max_cost', // query string for max value
          },
          title: 'Rent Cost',
        },
      ]
    },
    getFlags(item) {
      let flags = []

      // rating
      if (item.trip && item.trip.rating === 'Good') {
        flags.push('good_rated')
      }

      if (item.trip && item.trip.rating === 'Bad') {
        flags.push('bad_rated')
      }

      //refund

      if (item.is_refunded) {
        flags.push('refunded')
      }
      return flags
    },

    getFlagAlias(flag, data = {}) {
      console.log(data)
      let aliases = {
        good_rated: 'Good Rating',
        bad_rated: 'Bad Rating',
        refunded: `Rider Balance was Refunded`,
      }

      return flag in aliases ? aliases[flag] : '--'
    },

    async onInvoiceModalReq(id) {
      this.invoiceId = id
      this.$notify({
        group: 'generic',
        type: 'default',
        title: `Processing`,
        text: 'The action is being executed...',
      })

      await this.$http
        .get(useEndpoints.vehicleRental.getEmailTemplate(id))
        .then((res) => {
          this.invoiceHtmlString = res.data
          this.$modal.show('dynamicInvoiceModal')
        })
        .catch((err) => {
          console.log('email-template-err', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    async sendInvoiceEmail(id) {
      await this.$http
        .post(useEndpoints.vehicleRental.sendEmail(id))
        .then((res) => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: res.data,
            },
            3000
          )
        })
        .catch((err) => {
          console.log('send-email-err', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },

    getDirection() {
      var directionsService = new this.google.maps.DirectionsService()
      var directionsDisplay = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
      })

      var vm = this

      directionsService.route(
        {
          origin: this.getLatLng(this.trip.src_coord),
          destination: this.getLatLng(this.trip.dest_coord),
          travelMode: 'DRIVING',
        },
        function(response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response)
            var leg = response.routes[0].legs[0]
            vm.makeMarker(leg.start_location, 'start', 'title')
            vm.makeMarker(leg.end_location, 'end', 'title')
          } else {
            vm.makeMarker(response.request.origin.location, 'start', 'title')
            //window.alert('Directions request failed due to ' + status);
          }
        }
      )
    },
    makeMarker(position, icon, title) {
      new this.google.maps.Marker({
        position: position,
        map: this.$refs.map.$mapObject,
        icon:
          icon == 'start'
            ? {
                url: require(`@/assets/img/start_place.png`),
                scaledSize: { width: 63, height: 73 },
              }
            : {
                url: require(`@/assets/img/end_place.png`),
                scaledSize: { width: 63, height: 73 },
              },
        title: title,
      })
    },
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = parseFloat(position.split(',')[0])
      let lng = parseFloat(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },
    getDuration(...args) {
      return getHumanReadableDuration(...args)
    },
    getCostLine(item) {
      if (!item.invoice || typeof item.invoice === 'string') {
        return '--'
      }

      return `${item.invoice.currency.symbol} ${item.invoice.amount}`
    },
    initRentActionData() {
      this.tripAction.primaryKey = ''
      this.tripAction.processing = false

      this.tripAction.models = {
        extended_duration_in_mins: 0, // in min
        force_complete_reason: '',
        remarks: '',
      }
      this.tripAction.data.user = {
        id: '--',
        full_name: '--',
        role_name: '--',
        profile_pic: 'https://via.placeholder.com/150',
      }
      this.tripAction.data.pricing_info = {
        extended_duration_in_mins: 0,
        currency_symbol: '~',
        rent_time_extension_fees_per_minute: 0,
        tax_percentage: 0,
        estimated_extended_cost: 0,
        estimated_extension_tax_amount: 0,
        is_time_extended: false,
      }
    },
    getActionOptions(status) {
      console.log(status)
      return {
        // cancel: "Cancel",
        // end: "End"
      }
      // return status in statuses ? statuses[status] : "-";
    },
    getTooltipText({ status, cancel_reason, reject_reason }) {
      const tooltipTexts = {
        CANCELLED: `Reason: ${cancel_reason || 'N/A'}`,
        REJECTED: `Reason: ${reject_reason || 'N/A'}`,
      }
      return tooltipTexts[status] || '--'
    },
    shouldShowStatusTooltip(status) {
      return status === 'CANCELLED' || status === 'REJECTED'
    },
    getRentStatus(item) {
      const statuses = {
        REQUESTED: 'Pending',
        ACCEPTED: 'Accepted',
        CANCELLED: 'Cancelled',
        REJECTED: 'Rejected',
        TRIP_COMPLETED: 'Completed',
        DELIVERED: 'Rent Started',
      }
      if (item.status === 'TRIP_COMPLETED') {
        if (item.trip.status_forced) {
          return 'Force End'
        } else {
          return 'Completed'
        }
      } else {
        return statuses[item.status] || '--'
      }
    },
    getRentStatusBadge(item) {
      const badges = {
        REQUESTED: 'orange',
        ACCEPTED: 'blue',
        CANCELLED: 'red',
        REJECTED: 'blush',
        TRIP_COMPLETED: 'green',
        DELIVERED: 'purple',
      }
      if (item.status === 'TRIP_COMPLETED') {
        if (item.trip.status_forced) {
          return 'red'
        } else {
          return 'green'
        }
      } else {
        return badges[item.status] || 'blue'
      }
    },
    view(item) {
      console.log('item = ', item)
      this.$router.push(`/user-management/${item.trip_short_id}`)
    },
    getActionsEndpoint(id) {
      return useEndpoints.trip.update(id)
    },

    async onPerformAction({
      id,
      user,
      action,
      vehicle_category,
      vehicle_model,
      should_start_rent_after_accepting,
    } = {}) {
      if (this.$acl.canNotUpdate('trips')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }

      this.tripAction.primaryKey = id

      console.log('onPerformAction', id, action, vehicle_model)

      if (action === 'accept') {
        this.should_start_rent_after_accepting = should_start_rent_after_accepting
        this.currentRentId = id
        this.currentVehicleCategory = vehicle_category
        this.currentVehicleModel = vehicle_model
        this.showAcceptModal = true
        return
      }

      if (action === 'reject') {
        this.currentRentId = id
        this.showRejectModal = true
        return
      }

      if (action === 'deliver') {
        this.currentRentId = id
        this.showDeliverModal = true
        return
      }

      if (action === 'close') {
        this.$edgeStack.close(this.tripAction.esId)

        return
      }

      if (action === 'force-complete-open') {
        this.tripAction.data.user = user
        const url = `/dashboard/rentals/vehicle-rentals/${id}/complete-rental-preload/`

        this.tripAction.busy = true
        this.$edgeStack.open(this.tripAction.esId)
        await this.$http
          .get(url)
          .then((r) => {
            console.log('preLoadRes', r.data)
            this.tripAction.data.pricing_info = {
              ...r.data,
              extended_duration_in_mins: Math.floor(
                r.data.extended_duration_in_mins
              ),
            }
            this.tripAction.models.extended_duration_in_mins = Math.floor(
              r.data.extended_duration_in_mins
            )
          })
          .catch((err) => {
            console.warn('rentActionPreloadErr', err, err.response)
          })
          .finally(() => {
            this.tripAction.busy = false
          })
        return
      }
      if (action === 'force-complete-execute') {
        this.tripAction.processing = true
        const url = useEndpoints.vehicleRental.actions.forceComplete(id)
        const { models } = this.tripAction
        const data = new xMan(models).toFormData()

        if (!this.isRentalTimeExtended) {
          data.delete('extended_duration_in_mins')
        }

        await this.$http
          .post(url, data)
          .then((r) => {
            console.log(r.data)
            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: `Force Complete Rent`,
              text: r.data?.detail ?? `Successfully completed the action.`,
            })

            this.onPerformAction({ action: 'close' })
            this.$store.dispatch('fsTable/fetchData')
          })
          .catch((err) => {
            this.$notify(
              {
                group: 'bottomLeft',
                type: 'error',
                title: `Failed Action [Code: ${err?.response?.status}]`,
                text:
                  err.response?.data?.message ??
                  `Failed to execute force complete.`,
              },
              7000
            )
            console.log('rentActionErr', err, err.data)
          })
          .finally(() => {
            this.tripAction.processing = false
            this.initRentActionData()
          })
      }
    },
    onAcceptModalClose() {
      this.currentRentId = null
      this.showAcceptModal = false
    },
    onRejectModalClose() {
      this.currentRentId = null
      this.showRejectModal = false
    },
    onCancelModalClose() {
      this.currentRentId = null
      this.showCancelModal = false
    },
    onDeliverModalClose() {
      this.currentRentId = null
      this.showDeliverModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.child-trip-index-badge {
  border-radius: 50%;
  padding: 2px;
  margin-left: 8px;
  color: #fff;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
